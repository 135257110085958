import dynamic from 'next/dynamic'
import React, { FC } from 'react'

import { Container, Divider, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import LazyHydrate from 'react-lazy-hydration'

import FormSection from './components/FormSection'
import OrderingSection from './components/OrderingSection'

const BlogSection = dynamic(() => import('./components/BlogSection'))
const ChatSection = dynamic(() => import('./components/ChatSection'))
const SecuritySection = dynamic(() => import('./components/SecuritySection'))
const StepsSection = dynamic(() => import('./components/StepsSection'))
const JoinSection = dynamic(() => import('./components/JoinSection'))

const HomePageView: FC = () => {
  return (
    <>
      <Typography component="h1" sx={{ display: 'none' }}>
        <FormattedMessage defaultMessage="Platform to book a cake for your next event" />
      </Typography>

      <FormSection />

      <OrderingSection />

      <LazyHydrate whenVisible>
        <StepsSection />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <SecuritySection />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <ChatSection />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <Container maxWidth="lg">
          <Divider />
        </Container>
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <BlogSection />
      </LazyHydrate>

      <LazyHydrate whenVisible>
        <JoinSection />
      </LazyHydrate>
    </>
  )
}

export default HomePageView
