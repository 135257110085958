import Image from 'next/image'
import React from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Text from '~/components/base/Text'

import OrderingImg from '~/assets/images/orderring-img.jpg'

import SectionContainer from '../SectionContainer'

const OrderingSection = () => {
  return (
    <SectionContainer sx={{ pt: { xs: 5, md: 7 } }}>
      <Grid container spacing={2} mb={{ xs: 3, md: 5 }}>
        <Grid item xs={12} md={6}>
          <Text
            size={{ xs: 'h3', md: 'h2' }}
            component="h2"
            color="textPrimary"
            fontWeight={600}
          >
            <FormattedMessage
              defaultMessage="Ordering a cake has {br} never been easier"
              values={{ br: <br /> }}
            />
          </Text>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage defaultMessage="Search for bakers in your local area to create the perfect dessert for your next event.  Book and pay securely with CakerHQ." />
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{ overflow: 'hidden', borderRadius: { xs: '20px', md: '40px' } }}
      >
        <Image
          src={OrderingImg}
          layout="responsive"
          placeholder="blur"
          sizes="(max-width: 1160px) 70vw, 1100px"
          priority
          alt="Ordering a cake"
        />
      </Box>
    </SectionContainer>
  )
}

export default OrderingSection
