import { GetStaticProps } from 'next'
import { ReactElement } from 'react'

import { QueryClient, dehydrate } from 'react-query'

import MainLayout from '~/components/layouts/MainLayout'
import HomePageView from '~/views/Home/Home.view'

import { blogConfig } from '~/services/Query/articles/articles.query'

import { NextPageExtended } from '~/types/interfaces/nextjs'

const HomePage: NextPageExtended = () => {
  return <HomePageView />
}

HomePage.getLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>

HomePage.getMeta = () => ({
  title: 'Fast Online Cake Order Near Me',
  description:
    'CakerHQ - platform which helps to find a baker to book a cake for your next event and for cake makers to find their new customers.'
})

export const getStaticProps: GetStaticProps = async () => {
  const params = { limit: 4 }
  try {
    const queryClient = new QueryClient()

    await queryClient.prefetchQuery(
      [blogConfig.allArticles.key, params],
      blogConfig.allArticles.request
    )

    return {
      props: {
        dehydratedState: dehydrate(queryClient)
      }
    }
  } catch (error) {
    return { notFound: true }
  }
}

export default HomePage
